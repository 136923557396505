import {AuthStuff, useAuth} from "../providers/AuthProvider"
import {Dictionary, keyBy} from "lodash"
import {DaoTemplate} from "./dao/DaoTemplate"
import Dao = DaoTemplate.Dao
import HasId = DaoTemplate.HasId
import {ListResponse} from "./api/SimpleApis"

export namespace StoreUtils {

  export function useCallApiFn<C, S extends HasId>(
    postCommandAndGetUpdatedState : (accessToken : string | undefined, command: C) => Promise<S>,
    storeState                    : undefined | LocalState<S>,
    setStoreState                 : (_: LocalState<S>) => void,
  ) {
    const auth = useAuth()
    const updateLocalState = storeState ? updateLocalStateFn(storeState, setStoreState) : () => {}

    return async (cmd: C) => {
      const accessToken  = await auth.accessTokenPromise
      const updatedState = await postCommandAndGetUpdatedState(accessToken, cmd)

      updateLocalState([updatedState])
    }
  }

  export function useCallApiNFn<C, S extends HasId>(
    postCommandAndGetUpdatedState : (accessToken : string | undefined, command: C) => Promise<ReadonlyArray<S>>,
    storeState                    : undefined | LocalState<S>,
    setStoreState                 : (_: LocalState<S>) => void,
  ) {
    const auth = useAuth()
    const updateLocalState = storeState ? updateLocalStateFn(storeState, setStoreState) : () => {}

    return async (cmd: C) => {
      const accessToken   = await auth.accessTokenPromise
      const updatedStates = await postCommandAndGetUpdatedState(accessToken, cmd)

      updateLocalState(updatedStates)
    }
  }

  export type LocalState<S extends HasId> = {
    itemsById    : Dictionary<S>,
    snapshotTime : number,
  }

  type SetLocalState<I extends HasId> = (newState: LocalState<I>) => void

  export function updateLocalStateFn<S extends HasId>(
    currentState     : LocalState<S>,
    setCurrentState  : (newState: LocalState<S>) => void,
    ) {
    return (updatedItemStates : ReadonlyArray<S>) => {
      const newItemsById = { ...currentState.itemsById }
      updatedItemStates.forEach(updatedItemState => {
        newItemsById[updatedItemState.id] = updatedItemState
      })

      // we don't set snapshot time, as it's possible some other thread
      // modified something between
      setCurrentState({
        ...currentState!,
        itemsById: newItemsById,
      })
    }
  }

  const CHECK_INTERVAL_IN_MS = 60 * 1000
  export function initOrUpdateStoreState<I extends HasId>(params: {
    auth          : AuthStuff,
    dao           : Dao<I>,
    listApi       : (accessToken: string, since: number | undefined) => Promise<ListResponse<I>>,
    storeState    : LocalState<I> | undefined,
    setStoreState : SetLocalState<I>,
  }) {

    if (params.auth.tokens) {
      if (params.storeState === undefined) {
        initializeState(
          params.auth.accessTokenPromise,
          params.dao,
          params.listApi,
          params.setStoreState,
        )
      } else {
        const timeout = setTimeout(() => {
          updateState(
            params.auth.accessTokenPromise,
            params.dao,
            params.listApi,
            params.storeState!,
            params.setStoreState,
          )
        }, CHECK_INTERVAL_IN_MS)

        return () => clearTimeout(timeout)
      }
    } else {
      // do nothing
    }
  }

  // should sync between different tabs
  async function initializeState<I extends HasId>(
    accessTokenPromise : Promise<string | undefined>,
    dao                : DaoTemplate.Dao<I>,
    listApi            : (accessToken: string, since: number | undefined) => Promise<ListResponse<I>>,
    setState           : SetLocalState<I>,
  ) {
    console.debug("Initializing store")

    const accessToken      = await accessTokenPromise
    const itemsInDb        = await dao.getAllItems()
    const itemsInDbById    = keyBy(itemsInDb, item => item.id)
    const snapshotTimeInDb = await dao.getSnapshotTime()

    const listResponseFromServer    = await listApi(accessToken!, snapshotTimeInDb) // fixme accessToken
    const newItemsFromServer        = listResponseFromServer.items
    const newSnapshotTimeFromServer = listResponseFromServer.snapshotTime

    await dao.putSnapshotTime(newSnapshotTimeFromServer)

    if (newItemsFromServer.length > 0) {
      const newItemsFromServerById = keyBy(newItemsFromServer, item => item.id)
      await dao.putAllItems(listResponseFromServer.items)
      const updatedItemsById = { ...itemsInDbById, ...newItemsFromServerById }

      setState({
        itemsById    : updatedItemsById,
        snapshotTime : newSnapshotTimeFromServer,
      })
    } else {
      setState({
        itemsById    : itemsInDbById,
        snapshotTime : newSnapshotTimeFromServer,
      })
    }
  }

  export async function updateState<I extends HasId>(
    accessTokenPromise : Promise<string | undefined>,
    dao                : DaoTemplate.Dao<I>,
    listApi            : (accessToken: string, since: number | undefined) => Promise<ListResponse<I>>,
    state              : LocalState<I>,
    setState           : SetLocalState<I>,
  ) {
    const accessToken               = await accessTokenPromise
    const listResponseFromServer    = await listApi(accessToken!, state.snapshotTime)
    const newItemsFromServer        = listResponseFromServer.items
    const newSnapshotTimeFromServer = listResponseFromServer.snapshotTime

    await dao.putSnapshotTime(newSnapshotTimeFromServer)

    if (newItemsFromServer.length > 0) {
      const newItemsFromServerById = keyBy(newItemsFromServer, item => item.id)
      await dao.putAllItems(newItemsFromServer)

      const updatedItemsById = { ...state.itemsById, ...newItemsFromServerById }

      setState({
        itemsById    : updatedItemsById,
        snapshotTime : newSnapshotTimeFromServer,
      })
    } else {
      setState({
        itemsById    : state.itemsById,
        snapshotTime : newSnapshotTimeFromServer,
      })
    }
  }
}