import {Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Stack,} from "@mui/material"
import React from "react"
import Grid from "@mui/material/Unstable_Grid2"
import {useSnackbar} from "../../common/SnackbarProvider"
import {useTaskStore} from "../TaskStoreProvider"
import TaskModels from "../api/TaskModels"
import {
  ArchiveButton,
  CancelButton,
  NoButton,
  UnarchiveButton,
  UpdateButton,
  YesButton
} from "../../common/component/Buttons"
import {DatePicker} from "../../common/component/DatePicker"
import {Select} from "../../common/component/Select"
import {TextField} from "../../common/component/TextField"
import {updateOptionalString, updateString} from "../../common/model/UpdateField";
import Box from "@mui/material/Box";
import TaskState = TaskModels.TaskState;

// todo: answer what happens if the state is updated in the meantime?
export function EditTaskDialog(props: {
  isOpen     : boolean,
  setIsOpen  : (b: boolean) => void,
  task       : TaskState,
}) {
  const task = props.task

  const snackBar  = useSnackbar()
  const taskStore = useTaskStore()

  const [isArchiveDialogOpen,   setIsArchiveDialogOpen]   = React.useState<boolean>(false)
  const [isUnarchiveDialogOpen, setIsUnarchiveDialogOpen] = React.useState<boolean>(false)

  const [title,       setTitle]       = React.useState<string>(task.title)
  const [who,         setWho]         = React.useState<string>(task.assignedUsername)
  const [description, setDescription] = React.useState<string>(task.description)
  const [dueDate,     setDueDate]     = React.useState<undefined | string>(task.dueDate)

  const isInvalidForm = (
    title.trim().length < 3 ||
    description.trim().length < 3 ||
    (who !== "e" && who !== "t")
  )

  const handleUpdate = () => {
    if (!isInvalidForm) {
      const updateTaskCommand: TaskModels.UpdateTaskCommand = {
        taskId           : task.id,
        title            : updateString(title, task.title),
        assignedUsername : updateString(who, task.assignedUsername),
        description      : updateString(description, task.description),
        dueDate          : updateOptionalString(dueDate, task.dueDate),
      }

      taskStore
        .updateTask(updateTaskCommand)
        .then(_ => {
          snackBar.showSuccess("Task is successfully updated.")
          props.setIsOpen(false)
        })
        .catch(_ => {
          snackBar.showError("Task couldn't be updated.")
        })
    } else {
      // do nothing
    }
  }

  const handleCancel = () => {
    props.setIsOpen(false)
  }

  const handleArchive = () => {
    setIsArchiveDialogOpen(true)
  }

  const handleArchiveConfirm = () => {
    const archiveTaskCommand: TaskModels.ArchiveTaskCommand = {
      taskId : task.id,
    }

    taskStore
      .archiveTask(archiveTaskCommand)
      .then(_ => {
        snackBar.showSuccess("Task is successfully archived.")
        setIsArchiveDialogOpen(false)
      })
      .catch(_ => {
        snackBar.showError("Task couldn't be archived.")
      })
  }

  const handleUnarchive = () => {
    setIsUnarchiveDialogOpen(true)
  }

  const handleUnarchiveConfirm = () => {
    const unarchiveTaskCommand: TaskModels.UnarchiveTaskCommand = {
      taskId : task.id,
    }

    taskStore
      .unarchiveTask(unarchiveTaskCommand)
      .then(_ => {
        snackBar.showSuccess("Task is successfully unarchived.")
        setIsUnarchiveDialogOpen(false)
      })
      .catch(_ => {
        snackBar.showError("Task couldn't be unarchived.")
      })
  }

  return (
  <>
   <Dialog fullWidth maxWidth="sm" disableEscapeKeyDown open={props.isOpen}>
     <DialogTitle>Update task</DialogTitle>

     <DialogContent>
       <Grid container mt={0} spacing={2}>
         <TextField
           xs={6}
           label="Title"
           value={title}
           onChange={setTitle}
         />

         <Select
           xs={2}
           label="Who"
           value={who}
           onChange={setWho}
         >
           <MenuItem value="" sx={{fontStyle: "italic"}}>Unassigned</MenuItem>
           <MenuItem value="e">e</MenuItem>
           <MenuItem value="t">t</MenuItem>
         </Select>

         <DatePicker
           xs={4}
           label="Due (optional)"
           value={dueDate}
           onChange={setDueDate}
         />

         <TextField
           xs={12}
           label="Description"
           value={description}
           onChange={setDescription}
           multiline
           rows={4}
         />
       </Grid>
     </DialogContent>

      <DialogActions sx={{p: "0 24px 16px"}}>
        <Stack spacing={2} direction="row" width="100%">

          { task.status === "todo" ?
            props.task.isArchived
              ? <UnarchiveButton
                tooltip="This button unarchive the task"
                onClick={handleUnarchive}
              />
              : <ArchiveButton
                tooltip="This button archives the task"
                onClick={handleArchive}
              />
            : <></>
          }

          <Box flexGrow={1} />

          <CancelButton
            tooltip="This button closes the dialog (Esc)"
            onClick={handleCancel}
          />

          <UpdateButton
            tooltip={`This button updates the task (${ctrlOrCommandButton()} + Enter)`}
            onClick={handleUpdate}
            disabled={isInvalidForm}
          />
        </Stack>
      </DialogActions>
    </Dialog>

    <ArchiveTaskDialog isOpen={isArchiveDialogOpen} setIsOpen={setIsArchiveDialogOpen} onConfirm={handleArchiveConfirm}/>
    <UnarchiveTaskDialog isOpen={isUnarchiveDialogOpen} setIsOpen={setIsUnarchiveDialogOpen} onConfirm={handleUnarchiveConfirm}/>
   </>
  )
}

const ArchiveTaskDialog = (props: {
  isOpen    : boolean,
  setIsOpen : (isOpen : boolean) => void,
  onConfirm : () => void,
}) => {
  return (
    <ConfirmDialog
      title="Archive task"
      question="Are you sure you want to archive this task?"
      yesButtonTooltip={`This button archives the task (${ctrlOrCommandButton()} + Enter)`}
      {...props}
    />
  )
}

const UnarchiveTaskDialog = (props: {
  isOpen    : boolean,
  setIsOpen : (isOpen : boolean) => void,
  onConfirm : () => void,
}) => {
  return (
    <ConfirmDialog
      title="Unarchive task"
      question="Are you sure you want to unarchive this task?"
      yesButtonTooltip={`This button unarchives the task (${ctrlOrCommandButton()} + Enter)`}
      {...props}
    />
  )
}

const ConfirmDialog = (props: {
  title            : string,
  question         : string,
  yesButtonTooltip : string,
  isOpen           : boolean,
  setIsOpen        : (isOpen : boolean) => void,
  onConfirm        : () => void,
}) => {

  const handleCancel = () => {
    props.setIsOpen(false)
  }

  return (
    <Dialog open={props.isOpen} onClose={(_, reason) => {if(reason === 'backdropClick') {} else {props.setIsOpen(false)}}}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>{props.question}</DialogContent>

      <DialogActions sx={{p: "0 24px 16px"}}>
        <Stack spacing={2} direction="row" width="100%">
          <NoButton
            tooltip="Closes the dialog (Esc)"
            onClick={handleCancel}
          />

          <YesButton
            tooltip={props.yesButtonTooltip}
            onClick={props.onConfirm}
          />
        </Stack>
      </DialogActions>
    </Dialog>
  )
}

function ctrlOrCommandButton() {
  if (window.navigator.userAgent.includes("Mac")) {
    return "⌘"
  } else {
    return "Ctrl"
  }
}