import React, {useState} from "react";
import {Page} from "../../components/Page";
import {Link as RouterLink} from 'react-router-dom';
import {
  Badge,
  Button,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  PaletteColor,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme
} from "@mui/material"
import TextField from '@mui/material/TextField'
import AddIcon from '@mui/icons-material/Add'
import {ArrowDropDownIcon, DatePicker} from "@mui/x-date-pickers"
import {KeyboardArrowDown, KeyboardArrowRight} from "@mui/icons-material"
import {useArticleStore} from "../../writing-task/dao/ArticleStoreProvider"
import {NewArticleDialog} from "./NewArticleDialog"
import DoneIcon from '@mui/icons-material/Done'
import ClearIcon from '@mui/icons-material/Clear'
import DeleteIcon from '@mui/icons-material/Delete'
import Grid from "@mui/material/Unstable_Grid2"
import {WebsiteSelector} from "./WebsiteSelector"
import dayjs from "dayjs"
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import {SurferIcon} from "./SurferIcon"
import NotesIcon from '@mui/icons-material/Notes'
import {useSnackbar} from "../../common/SnackbarProvider"
import WritingModels from "./WritingModels"
import {TaskStore, useTaskStore} from "../../task/TaskStoreProvider";
import Box from "@mui/material/Box";
import {useClock} from "../../common/time/ClockProvider";
import {sortBy} from "lodash";
import {TaskListItem} from "../../task/component/TaskListItem";
import {NewTaskDialog} from "../../task/component/NewTaskDialog";
import ListIcon from '@mui/icons-material/List';
import AssignmentIcon from "@mui/icons-material/Assignment";

export function AllArticlesPage() {

  const articleStore = useArticleStore();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);

  const [statusFilter,      setStatusFilter     ] = React.useState<"all" | "not-ready" | "ready" | "recorded">("not-ready");
  const [websiteNameFilter, setWebsiteNameFilter] = React.useState<string | undefined>(undefined);
  const [mainKeywordFilter, setMainKeywordFilter] = React.useState<string | undefined>(undefined);
  const [pageKeywordFilter, setPageKeywordFilter] = React.useState<string | undefined>(undefined);
  const [writerNameFilter,  setWriterNameFilter ] = React.useState<string | undefined>(undefined);

  const articlesInProgress = Object.values(articleStore.articlesById)
    .filter(article => {
      if (statusFilter === "all") {
        return true;
      } else if (statusFilter === "not-ready") {
        return article.status !== "Done" && article.status !== "Ready" && article.status !== "Recorded"
      } else if (statusFilter === "ready") {
        return article.status === "Ready"
      } else {
        return article.status === "Recorded"
      }
    })
    .filter(article => {
      return websiteNameFilter === undefined || websiteNameFilter === article.websiteName;
    })
    .filter(article => {
      return mainKeywordFilter === undefined || article.mainKeyword.toLowerCase().includes(mainKeywordFilter.toLowerCase());
    })
    .filter(article => {
      return pageKeywordFilter === undefined || article.pageKeyword.toLowerCase().includes(pageKeywordFilter.toLowerCase());
    })
    .filter(article => {
      return writerNameFilter === undefined || article.writerName.toLowerCase().includes(writerNameFilter.toLowerCase());
    })
    .sort((a, b) => ((a.plannedPublicationDate || '') + a.websiteName).localeCompare(((b.plannedPublicationDate || '') + b.websiteName)));

  return (
    <>
      <Page title={'Articles In Progress'}>
        <Grid container spacing={4}>
          <Grid sm={9}>
            <Stack>
              <Stack pt={'1em'} direction={'row'} spacing={2} display={'block'}>
                <Button fullWidth={false} variant="contained" startIcon={<AddIcon/>} onClick={() => setIsOpen(true)}>Create Article</Button>
                <Button fullWidth={false} variant="contained" startIcon={<AddIcon/>} onClick={() => setIsOpen2(true)}>Import Planning</Button>
              </Stack>
              <Stack pt={'1em'} direction={'row'} spacing={2} display={'block'}>
                <ToggleButtonGroup
                  color="primary"
                  size="small"
                  value={statusFilter}
                  onChange={(_, newValue) => setStatusFilter(newValue)}
                  exclusive
                  aria-label="Platform"
                >
                  <ToggleButton value="all">All</ToggleButton>
                  <ToggleButton value="not-ready">Not Ready</ToggleButton>
                  <ToggleButton value="ready">Ready</ToggleButton>
                  <ToggleButton value="recorded">Recorded</ToggleButton>
                </ToggleButtonGroup>
                <WebsiteSelector extraValues={["Show All"]} sx={{width: "10em"}} size="small" onChange={event => { event.target.value === "Show All" ? setWebsiteNameFilter(undefined) : setWebsiteNameFilter(event.target.value) }}/>
                <TextField sx={{width: "10em"}} size="small" label="Main Keyword" placeholder="Show All" onChange={event => { event.target.value === undefined || event.target.value.length === 0 ? setMainKeywordFilter(undefined) : setMainKeywordFilter(event.target.value) }}/>
                <TextField sx={{width: "10em"}} size="small" label="Page Keyword" placeholder="Show All" onChange={event => { event.target.value === undefined || event.target.value.length === 0 ? setPageKeywordFilter(undefined) : setPageKeywordFilter(event.target.value) }}/>
                <TextField sx={{width: "10em"}} size="small" label="Writer Name" placeholder="Show All" onChange={event => { event.target.value === undefined || event.target.value.length === 0 ? setWriterNameFilter(undefined) : setWriterNameFilter(event.target.value) }}/>
              </Stack>
            </Stack>
          </Grid>
          <Grid sm={12}>
            <ArticlesInProgressTable articlesInProgress={articlesInProgress}/>
          </Grid>
        </Grid>
      </Page>

      <NewArticleDialog isOpen={isOpen} setIsOpen={setIsOpen}/>
      <ImportPlanningDialog isOpen={isOpen2} setIsOpen={setIsOpen2}/>
    </>
  )
}

function ImportPlanningDialog(props: {
  isOpen: boolean,
  setIsOpen: (b: boolean) => void
}) {
  const articleStore = useArticleStore()
  const snackbar = useSnackbar();

  const importUrlTextRef = React.useRef<HTMLInputElement>(null)

  const onImport = () => {
    const googleSheetsUrl = importUrlTextRef.current?.value
    if (googleSheetsUrl !== undefined) {
      const importKeywordSheetCommand = {
        googleSheetsUrl,
      }
      articleStore
          .importKeywordSheet(importKeywordSheetCommand)
          .then(_ => {
            snackbar.showSuccess("Keyword planning entries are imported")
          })
          .catch(e => {
            console.log(e)
            snackbar.showError("Error during keyword planning import")
          })
    }
  }

  return (
    <Dialog open={props.isOpen} onClose={() => props.setIsOpen(false)} fullWidth maxWidth="md">
      <DialogTitle>Import keyword planning sheet</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} mt="4px">
          <Grid xs={12}>
            <TextField size="small" type="url" label="Google Sheet URL" fullWidth inputRef={importUrlTextRef}></TextField>
          </Grid>
          <Grid xs={2}>
            <Button variant="contained" color="primary" fullWidth onClick={onImport}>Import</Button>
          </Grid>
          <Grid xs={2}>
            <Button variant="outlined" color="secondary" fullWidth onClick={() => props.setIsOpen(false)}>Cancel</Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

function ArticlesInProgressTable(props: {
  articlesInProgress: ReadonlyArray<WritingModels.Article>,
}) {
  return (
      <Table size={'small'}>
      <TableHead>
        <TableRow>
          <TableCell align="left"   sx={{width: "5%"}}/>
          <TableCell align="left"   sx={{width: "12.5%"}}>Website</TableCell>
          <TableCell align="left"   sx={{width: "17.5%"}}>Main Keyword</TableCell>
          <TableCell align="left"   sx={{width: "18%"}}  >Page Keyword</TableCell>
          <TableCell align="center" sx={{width: "12%"}}  >Status</TableCell>
          <TableCell align="right"  sx={{width: "7.5%"}} >Writer</TableCell>
          <TableCell align="right"  sx={{width: "7.5%"}} >Price</TableCell>
          <TableCell align="right"  sx={{width: "7.5%"}} >Publication</TableCell>
          <TableCell align="right"  sx={{width: "12.5%"}}>Links</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.articlesInProgress.map(article => <ArticleInProgressRow key={article.id} article={article}/>)}
      </TableBody>
    </Table>
  );
}

function WritingStatusDropdown(props: {
  article: WritingModels.Article,
}) {
  const snackbar = useSnackbar();
  const articleStore = useArticleStore();
  const theme = useTheme();
  const colorForArticle = paletteForArticle(props.article, theme);

  return (

    <Select 
      id={`writing-task-row-status-selector-${props.article.id}`}
      value={props.article.status} 
      size="small" 
      onChange={e => {
        const updateWritingCommand: WritingModels.UpdateArticleCommand = {
          taskId                 : props.article.id,
          status                 : e.target.value,
          websiteName            : undefined,
          mainKeyword            : undefined,
          categoryKeyword        : undefined,
          pageKeyword            : undefined,
          writerName             : undefined,
          keywordResearchUrl     : undefined,
          contentEditorUrl       : undefined,
          plannedPublicationDate : undefined,
          comments               : undefined,
          writingCostInUsd       : undefined,
          publishedUrl           : undefined,
        }
        articleStore
          .updateArticle(updateWritingCommand)
          .catch(_ => snackbar.showError("Couldn't update article status"));
      }}
      sx={{
        textAlign: 'center',
        fontSize: "0.875rem",
        height: "2.1rem",
        backgroundColor: colorForArticle.main,
        color: colorForArticle.contrastText,
        '& MuiSelect-icon-root': { color: 'black'}
      }} 
      fullWidth
      IconComponent={(props) => <ArrowDropDownIcon {...props} sx={{ color: `${colorForArticle.contrastText}!important` }}/>}>
      <MenuItem value="To Do">To Do</MenuItem>
      <MenuItem value="Prepare Surfer">Prepare Surfer</MenuItem>
      <MenuItem value="Send link">Send link</MenuItem>
      <MenuItem value="In progress">In progress</MenuItem>
      <MenuItem value="Under review">Under review</MenuItem>
      <MenuItem value="Photo change">Photo change</MenuItem>
      <MenuItem value="Add link">Add link</MenuItem>
      <MenuItem value="Ready">Ready</MenuItem>
      <MenuItem value="Recorded">Recorded</MenuItem>
      <MenuItem value="Done">Done</MenuItem>
    </Select>
  );
}

function paletteForArticle(article: WritingModels.Article, theme: Theme) {
  switch(article.status) {
    case "To Do":          return WhitePaletteColor;
    case "Prepare Surfer": return theme.palette.primary;
    case "Send link":      return theme.palette.secondary;
    case "In progress":    return LightYellow;
    case "Under review":   return theme.palette.info;
    case "Photo change":   return theme.palette.warning;
    case "Add link":       return theme.palette.info;
    case "Ready":          return theme.palette.success;
    case "Recorded":       return theme.palette.success;
    case "Done":           return theme.palette.success;
  }
}

const WhitePaletteColor: PaletteColor = {
  light:        "#eeeeee",
  main:         "#eeeeee",
  dark:         "#eeeeee",
  contrastText: "#222222",
}

const LightYellow: PaletteColor = {
  light:        "#ffe69e",
  main:         "#ffe69e",
  dark:         "#ffe69e",
  contrastText: "#222222",
}

function ArticleInProgressRow(props: { article: WritingModels.Article }) {
  const snackbar = useSnackbar();
  const taskStore = useTaskStore();
  const article = props.article;
  const articleStore = useArticleStore();
  const [isOpen, setIsOpen] = React.useState(false);

  const rowSx = (theme: Theme) => {
    return {
      borderLeftWidth: "4px",
      borderLeftStyle: "solid",
      borderLeftColor: isOpen ? theme.palette.primary.main : "transparent",
    };
  };

  const websiteNameRef        = React.useRef<HTMLInputElement>(null);
  const mainCategoryRef       = React.useRef<HTMLInputElement>(null);
  const categoryKeywordRef    = React.useRef<HTMLInputElement>(null);
  const pageKeywordRef        = React.useRef<HTMLInputElement>(null);
  const keywordResearchUrlRef = React.useRef<HTMLInputElement>(null);
  const contentUrlRef         = React.useRef<HTMLInputElement>(null);
  const writerNameRef         = React.useRef<HTMLInputElement>(null);
  const publishDateRef        = React.useRef<HTMLInputElement>(null);
  const commentsRef           = React.useRef<HTMLInputElement>(null);
  const writingCostInUsdRef   = React.useRef<HTMLInputElement>(null);
  const publishedUrlRef       = React.useRef<HTMLInputElement>(null);

  const onUpdate = () => {
    const updateCommand: WritingModels.UpdateArticleCommand =  {
      taskId:                 article.id,
      websiteName:            websiteNameRef.current?.value,
      mainKeyword:            mainCategoryRef.current?.value,
      categoryKeyword:        categoryKeywordRef.current?.value,
      pageKeyword:            pageKeywordRef.current?.value,
      status:                 article.status,
      writerName:             writerNameRef.current?.value,
      keywordResearchUrl:     keywordResearchUrlRef.current?.value,
      contentEditorUrl:       contentUrlRef.current?.value,
      plannedPublicationDate: publishDateRef.current?.value,
      comments:               commentsRef.current?.value,
      writingCostInUsd:       writingCostInUsdRef.current?.value,
      publishedUrl:           publishedUrlRef.current?.value,
    };

    articleStore
      .updateArticle(updateCommand)
      .then(_ => setIsOpen(false))
      .catch(_ => snackbar.showError("Couldn't update article"))
      ;
  }

  const onDelete = () => {
    const deleteCommand: WritingModels.DeleteArticleCommand = {
      taskId: article.id,
    };
    articleStore
      .deleteArticle(deleteCommand)
      .then(_ => {
        setIsOpen(false);
        setAnchorEl(undefined);
      })
      .catch(_ => snackbar.showError("Couldn't delete article"))
      ;
  }

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement>();
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(undefined);
  };

  return <>
    <TableRow id={`article-row-${article.id}`} sx={rowSx}>
      <TableCell align="left">
        <IconButton size="small" onClick={() => setIsOpen(!isOpen)}>{isOpen ? <KeyboardArrowDown/> : <KeyboardArrowRight/>}</IconButton>
      </TableCell>
      <TableCell align="left">{article.websiteName}</TableCell>
      <TableCell align="left">{article.mainKeyword}</TableCell>
      <TableCell align="left">{article.pageKeyword}</TableCell>
      <TableCell align="left"><WritingStatusDropdown article={article}/></TableCell>
      <TableCell align="right">{article.writerName || '-'}</TableCell>
      <TableCell align="right">{article.writingCostInUsd || '-'}</TableCell>
      <TableCell align="right">{article.plannedPublicationDate || '-'}</TableCell>
      <TableCell align="right">
        <Stack
          direction="row-reverse"
          spacing={0}
        >
          { article.contentEditorUrl ? <IconButton size="small" title="Go to Surfer" component={RouterLink} to={article.contentEditorUrl} target="_blank"><SurferIcon/></IconButton> : <></> }
          { article.publishedUrl ? <IconButton size="small" title="Go to Live URL" component={RouterLink} to={article.publishedUrl} target="_blank"><OpenInNewIcon/></IconButton> : <></> }
          { article.comments ? <IconButton size="small" disableRipple={true} title={article.comments}><NotesIcon/></IconButton> : <></> }
          <TaskBadge article={article} taskStore={taskStore}/>
        </Stack>
      </TableCell>
    </TableRow>
    <TableRow sx={rowSx}>
      <TableCell sx={theme => ({ paddingBottom: 0, paddingTop: 0, borderBottomColor: isOpen ? theme.palette.divider : "transparent"})} colSpan={9}>
        <Collapse in={isOpen} timeout={0} unmountOnExit sx={{mb: '.75em', mt: '.75em'}}>
          <Grid container spacing={2}>
            <Grid container spacing={2} xs={9}>
              <Grid xs={12}><Typography variant="h6" gutterBottom component="div">Details</Typography></Grid>

              <Grid xs={6}><WebsiteSelector size="small" defaultValue={article.websiteName}                                          inputRef={websiteNameRef} required fullWidth/></Grid>
              <Grid xs={6}><TextField size="small" defaultValue={article.mainKeyword}        label="Main Keyword"                    inputRef={mainCategoryRef} required fullWidth/></Grid>
              <Grid xs={6}><TextField size="small" defaultValue={article.categoryKeyword}    label="Category Keyword (Optional)"     inputRef={categoryKeywordRef} fullWidth/></Grid>
              <Grid xs={6}><TextField size="small" defaultValue={article.pageKeyword}        label="Page Keyword"                    inputRef={pageKeywordRef} required fullWidth/></Grid>
              <Grid xs={6}><TextField size="small" defaultValue={article.keywordResearchUrl} label="Keyword Research URL (Optional)" inputRef={keywordResearchUrlRef} fullWidth/></Grid>
              <Grid xs={6}><TextField size="small" defaultValue={article.contentEditorUrl}   label="Content URL (Optional)"          inputRef={contentUrlRef} fullWidth/></Grid>

              <Grid xs={6}><TextField size="small" defaultValue={article.writerName}         label="Writer (Optional)"               inputRef={writerNameRef} fullWidth/></Grid>
              <Grid xs={6}><DatePicker label="Publish Date" defaultValue={dayjs(article.plannedPublicationDate)}                     inputRef={publishDateRef} slotProps={{ textField: { size: "small", fullWidth: true, required: true } }}/></Grid>
              <Grid xs={6}><TextField  size="small" defaultValue={article.writingCostInUsd}   label="Price (Optional)"               inputRef={writingCostInUsdRef} fullWidth/></Grid>
              <Grid xs={6}><TextField  size="small" defaultValue={article.publishedUrl}       label="Published URL (Optional)"       inputRef={publishedUrlRef} fullWidth/></Grid>
              <Grid xs={12}><TextField size="small" defaultValue={article.comments}           label="Comments"                       inputRef={commentsRef} fullWidth multiline rows={4}/></Grid>

              <Grid xs={12} sx={{mt: ".25em", mb: ".75em"}}>
                <Stack spacing={2} direction="row">
                  <Button variant="contained" color="primary" onClick={onUpdate} startIcon={<DoneIcon/>}>Update</Button>
                  <Button variant="outlined" color="secondary" onClick={() => setIsOpen(false)} startIcon={<ClearIcon/>}>Cancel</Button>
                  <div style={{marginLeft: 'auto'}}/>
                  <Button variant="outlined" color="warning" onClick={handleClick} startIcon={<DeleteIcon/>}>Delete</Button>
                  <Menu
                    id="delete-button-menu"
                    anchorEl={anchorEl}
                    open={open}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={handleClose}>
                      <ListItemIcon>
                        <ClearIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>No, don't delete</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={onDelete}>
                      <ListItemIcon>
                        <DeleteIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Yes, delete it</ListItemText>
                    </MenuItem>
                  </Menu>
                </Stack>
              </Grid>
            </Grid>
            <Grid xs={3}>
              <TasksPanel writingId={article.id}/>
            </Grid>
          </Grid>
        </Collapse>
      </TableCell>
    </TableRow>
  </>;
}

const TasksPanel = (props: { writingId: string }) => {
  const [isNewTaskDialog, setIsNewTaskDialog] = useState(false)
  const clock     = useClock()
  const taskStore = useTaskStore()
  const snackbar  = useSnackbar()

  const tasksForContent = taskStore.tasksByWritingId[props.writingId] || []
  const tasksToDisplay  = sortBy(tasksForContent, task => task.created)

  return (
    <Box hidden={false}>
      { tasksToDisplay.length > 0
        ? <Stack spacing={1}>
          { tasksToDisplay.map(task => <TaskListItem key={task.id} task={task} taskStore={taskStore} snackBar={snackbar} currentDay={clock.currentDay}/>)}
        </Stack>
        : <Typography variant="body1">There are no tasks assigned to this content</Typography>
      }
      <Stack direction="row" spacing={2} mt={"1.5em"}>
        <Button variant="contained" color="primary" startIcon={<AddIcon/>} onClick={(e) => { setIsNewTaskDialog(true); e.currentTarget.blur(); }}>Create New Task</Button>
      </Stack>
      <NewTaskDialog isOpen={isNewTaskDialog} setIsOpen={setIsNewTaskDialog} writingId={props.writingId}/>
    </Box>
  )
}

const TaskBadge = (props: {article: WritingModels.Article, taskStore: TaskStore}) => {
  const tasksForContent = props.taskStore.tasksByWritingId[props.article.id]
  const toDoCount = tasksForContent?.filter(task => task.status === "todo").length

  return (
    <Box sx={{ width: "30px", pt: "4px"}}>
      { toDoCount
        ?
          <Badge badgeContent={toDoCount} color="warning" sx={{mr: "6px"}}>
            <AssignmentIcon color="action" />
          </Badge>
        : <></>
      }
    </Box>
  )
}